const WidgetChange = ({ Change }) => {
  return (
    <div className="col-sm-6 col-md pt-2 align-items-center">
      <div className="h-100">
        <div className="align-self-center">
          <h6 className="text-center  pt-2">24hr change:</h6>
          <h3 className="text-center">{Change}</h3>
        </div>
      </div>
    </div>
  );
};

export default WidgetChange;
