import './Widgets.css';

const WidgetMC = ({ MarketCap }) => {
  return (
    <div className="col-sm-6 col-md pt-2 align-items-center">
      <div className="h-100">
        <a
          className="text-decoration-none"
          href="https://etherscan.io/address/0xec8761a0a73c34329ca5b1d3dc7ed07f30e836e2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="align-self-center">
            <h6 className="text-center pt-2">
              Chainlink MC/USD<i className="bi bi-box-arrow-up-right m-2"></i>
            </h6>

            <h3 className="text-center">
              {MarketCap}
              <span></span>
            </h3>
          </div>
        </a>
      </div>
    </div>
  );
};

export default WidgetMC;
