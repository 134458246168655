import MarketChart from './Chart';

const Main = ({ token }) => {
  return (
    <div className="row mt-1 pt-2 d-none d-lg-block">
      <MarketChart token={token} />
    </div>
  );
};

export default Main;
