function Social() {
  return (
    <div className="d-flex justify-content-center align-self-center mx-1">
      <a
        href="https://twitter.com/dip_zero"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="bi bi-twitter m-1"></i>
      </a>

      <a
        href="https://www.instagram.com/namethedip/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="bi bi-instagram m-1"></i>
      </a>
    </div>
  );
}

export default Social;
