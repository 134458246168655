import React, { useEffect } from 'react';
import './Sticker.css';


function Sticker() {
  return(
    <div id='product-component-1731080564551'>Buy Sticker</div>
  )
}

export default Sticker;
