import { ethers } from 'ethers';
import ABI from '../ntdAbi.json';
import './NTDForm.css';
import { useState, useEffect } from 'react';
import dayjs, { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import axios from 'axios';

extend(utc);

require('dotenv').config();

const NTDForm = ({ signer, NTDAddress, disconnect }) => {
  //define variables
  const [dipName, setDipName] = useState('');
  const [dipId, setDipId] = useState();
  const [dipDate, setDipDate] = useState();
  const [dateArray, setDateArray] = useState([]);
  const [listExists, setListExists] = useState(false);
  const [loading, setLoading] = useState(false);

  //get address tokenlist
  useEffect(() => {
    setLoading(true);
    const getTokenList = async () => {
      const fetchList = async () => {
        try {
          const response = await axios.get('addressTokens', {
            params: { address: await signer.getAddress() },
          });
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      const ownerTokensList = await fetchList();
      setDateArray(ownerTokensList);
      setLoading(false);
    };
    getTokenList();
  }, [signer]);

  useEffect(() => {
    dateArray.length !== 0 && setListExists(true);
  }, [dateArray]);

  //name the dip contract
  async function nameTheDip(dipId, dipName) {
    const dipContract = new ethers.Contract(NTDAddress, ABI.abi, signer);
    await dipContract.nameTheDip(dipId, dipName).then(
      (result) => {},
      (error) => {
        console.log(error);
      }
    );
  }

  //form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    nameTheDip(dipId, dipName);
    setDipName('Name the DIP');
    console.log('dipId:', dipId);
    console.log('date:', dipDate);
    console.log('name:', dipName);
  };

  return (
    <div>
      {loading ? (
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : listExists ? (
        <form
          className="d-flex form-row align-items-center"
          role="button"
          onSubmit={handleSubmit}
        >
          <div className="col-auto">
            <label>
              <select
                className="form-select bg-transparent text-white"
                aria-label="Date select"
                value={dipId}
                onChange={(e) => setDipId(e.target.value)}
              >
                <option defaultValue className="bg-dark text-white">
                  Choose DIP
                </option>
                {dateArray.map((e) => (
                  <option
                    className="bg-dark text-white"
                    key={e.key}
                    value={e.key}
                  >
                    {e.date === 0 ? e.key.toString() : e.key.toString()}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="col-auto">
            <label>
              <input
                className="form-control bg-transparent text-white"
                id="NTD"
                type="text"
                maxLength={30}
                placeholder="Name the DIP"
                aria-label="DIP name"
                value={dipName}
                onChange={(e) => setDipName(e.target.value)}
              />
            </label>
          </div>
          <div className="col-auto">
            <button className="btn btn-outline-light" type="submit">
              NAME
            </button>
          </div>
          <div className="col-auto">
            <button
              className="col-auto btn btn-outline-light"
              onClick={disconnect}
            >
              Disconnect
            </button>
          </div>
        </form>
      ) : (
        <div className="row">
          <h6 className="col-auto d-flex m-0 align-items-center">
            Buy a DIP on OpenSea
          </h6>
          <button
            className="col-auto btn btn-outline-light"
            onClick={disconnect}
          >
            Disconnect
          </button>
        </div>
      )}
    </div>
  );
};

export default NTDForm;
