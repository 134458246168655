import React from 'react';
import './TokenSlide.css';
import TokenData from './TokenData';

function TokenSlide({ token, logo }) {
  return (
    <div className="imageContainer">
      <img
        className={token.tokenURI === undefined ? 'logoImg' : 'tokenImg'}
        src={token.tokenURI === undefined ? logo : token.tokenURI}
        alt="..."
      />
      <TokenData token={token} />
    </div>
  );
}

export default TokenSlide;
