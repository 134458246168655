const Widget = ({ DaysElapsed }) => {
  return (
    <div className="col-sm-6 col-md pt-2 align-items-center">
      <div className="h-100">
        <div className="align-self-center">
          <h6 className="text-center pt-2">Days since last dip:</h6>
          <h3 className="text-center">{DaysElapsed}</h3>
        </div>
      </div>
    </div>
  );
};

export default Widget;
