import Chart from 'react-apexcharts';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './Chart.css';

const MarketChart = ({ token }) => {
  const [baseChart, setBaseChart] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartFocus, setChartFocus] = useState(token);
  const [dipPointFocus, setDipPointFocus] = useState([]);

  //set baseChart dates
  useEffect(() => {
    const getChartData = async () => {
      const chartDataFromServer = await fetchChartData();
      setChartData(chartDataFromServer);
      setBaseChart(chartDataFromServer);
    };
    getChartData();
    return () => {
      setBaseChart({});
    };
  }, []);

  //get chart data from server
  const fetchChartData = async () => {
    try {
      const response = await axios.get('/chartData');

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  //setchartFocus on current image slide.
  useEffect(() => {
    setChartFocus(token);
  }, [token]);

  //change chart based on token. If "about" or non-image slide then return baseChart
  useEffect(() => {
    updateChart();
  }, [chartFocus]);

  //function to change chartFocus
  const updateChart = async () => {
    !chartFocus
      ? setChartData(baseChart)
      : !chartFocus.tokenChart?.length
      ? setChartData(baseChart)
      : setChartData(chartFocus.tokenChart);
  };

  const options = {
    // annotations: {
    //   points: dipPointFocus,
    // },
    chart: {
      id: 'marketChart',
      toolbar: {
        show: true,
        tools: {
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          download: true,
          reset: true,
        },
      },
    },
    fontFamily: 'VT323, monospace',
    animations: {
      enabled: true,
      easing: 'easein',
      speed: 500,
      dynamicAnimation: { enabled: false, speed: 500 },
    },

    tooltip: {
      enabled: true,
      marker: false,
      x: { format: 'yyyy MM dd' },
      style: {
        fontSize: '24px',
        fontFamily: 'VT323, monospace',
      },
      theme: 'dark',
      fixed: { enabled: true, position: 'topLeft', offsetX: 75 },
      y: {
        formatter: (value) => {
          return Intl.NumberFormat('en-US', {
            notation: 'compact',
            compactDisplay: 'short',
            maximumFractionDigits: 2,
          }).format(value);
        },
      },
    },

    xaxis: {
      type: 'datetime',
      labels: {
        rotate: 45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        style: {
          colors: '#CCCCCC',
          fontFamily: 'VT323, monospace',
        },
      },
      axisBorder: { show: false, color: '#CCCCCC' },
      axisTicks: { show: true, color: '#CCCCCC' },
    },

    yaxis: {
      labels: {
        style: {
          colors: '#CCCCCC',
          fontFamily: 'VT323, monospace',
        },
        formatter: (value) => {
          return Intl.NumberFormat('en-US', {
            currency: 'USD',
            notation: 'compact',
            // compactDisplay: 'short',
            maximumFractionDigits: 1,
          }).format(value);
        },
      },
    },

    grid: { yaxis: { lines: { show: false } } },
    stroke: { colors: '#CCCCCC', width: 1.5 },
    noData: {
      text: 'Loading...',
    },

    plotOptions: {
      candlestick: {
        colors: { upward: '#FFFFFF', downward: '#909090' },

        wick: { useFillColor: true },
      },
    },
  };

  const series = [
    {
      name: 'marketCap',
      data: chartData,
    },
  ];

  return (
    <div className="Chart row d-flex align-items-center">
      <Chart
        options={options}
        series={series}
        type="line"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default MarketChart;
