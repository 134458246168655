import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="About">
      <div>
        <h5 className="row d-flex justify-content-center">name the dip</h5>
        <h6 className="row d-flex justify-content-center">how it works</h6>

        <li className="row  d-flex justify-content-center">
          10% drop in Market Cap (MC) within 24 hours is a dip
        </li>
        <li className="row d-flex justify-content-center">
          dips within 7 days of each other are treated as one
        </li>
        <li className="row d-flex justify-content-center">
          dip is assigned date with lowest MC
        </li>
        <li className="row d-flex justify-content-center">
          a dip's NFT will be realeased no more than 30 days after
        </li>
        <li className="row d-flex justify-content-center">
          only one NFT per dip
        </li>
        <br />

        <h6 className="row d-flex justify-content-center">naming</h6>
        <li className="row d-flex justify-content-center">
          20 character limit; no leading or trailing "spaces"
        </li>
        <li className="row d-flex justify-content-center">
          only alphanumerical characters are allowed; case insensitive
        </li>
        <li className="row d-flex justify-content-center">
          no dip can have the same name
        </li>
        <li className="row d-flex justify-content-center">
          can only be named once
        </li>
      </div>
    </div>
  );
};

export default About;
