import React from 'react';
import './TokenData.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

function TokenData({ token }) {
  return (
    <div>
      <div className="tokenData">
        {/* id */}
        <h5>{token.tokenId}</h5>
        {/* date */}
        {token.date === undefined ? (
          <h6>working on it</h6>
        ) : (
          <h6>{dayjs.unix(token.date).utc().format('YYYY.MM.DD')}</h6>
        )}
        {/* name */}
        {token.name === undefined ? (
          <h6>not named yet</h6>
        ) : (
          <h6> name: {token.name}</h6>
        )}
        {/* change */}
        <h6>
          {new Intl.NumberFormat('en', {
            minimumFractionDigits: 3,
            style: 'percent',
          }).format(token.change)}
        </h6>
        {/* other */}
        {!token.NFTcollection?.length ? null : (
          <a href={token.NFTcollection}>{token.NFTcollection?.slice(8)}</a>
        )}
      </div>
      <div className="opensea">
        <a
          href={`https://opensea.io/assets/ethereum/0xeeab96006855bb9d6fd9ace5122cb59b91a8af59/${token.tokenId}`}
        >
          OpenSea
        </a>
      </div>
    </div>
  );
}

export default TokenData;
