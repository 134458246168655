import './Widgets.css';

const Widget = ({ Name }) => {
  return (
    <div className="col-sm-6 col-md pt-2 align-items-center">
      <div className="h-100">
        <div className="align-self-center">
          <h6 className="text-center pt-2">Next dip name:</h6>
          {{ Name } === undefined ? (
            <h3 className="text-center">TBD</h3>
          ) : (
            <h3 className="text-center">{Name}</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default Widget;
