import React, { useState, useEffect } from 'react';
import './Carousel.css';
import About from './About';
import TokenSlide from './TokenSlide';
import logo from '../image/logo.png';

const Carousel = ({ tokenList, showToken }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(0);
  const [transitionEnabled, setTransitionEnabled] = useState(true);
  const [touchPosition, setTouchPosition] = useState(null);
  const [show, setShow] = useState(2);
  const [infiniteLoop, setInfiniteLoop] = useState(true);

  useEffect(() => {
    setLength(tokenList.length + 1);
  }, [tokenList]);

  const next = () => {
    if (currentIndex < length) {
      setTransitionEnabled(true);
      setCurrentIndex((prevState) => prevState + 1);
    } else if (currentIndex === length) {
      setCurrentIndex(length + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setTransitionEnabled(true);
      setCurrentIndex((prevState) => prevState - 1);
    } else if (currentIndex === 0) {
      setCurrentIndex(-1);
    }
  };

  //pass tokenId to Parent
  useEffect(() => {
    showToken(currentIndex);
  }, [length, currentIndex]);

  const handleTransitionEnd = () => {
    if (currentIndex < 0) {
      setTransitionEnabled(false);
      setCurrentIndex(length - 1);
    } else if (currentIndex >= length) {
      setTransitionEnabled(false);
      setCurrentIndex(0);
    }
  };

  const renderExtraPrev = () => {
    let output = [];
    for (let index = 1; index <= 2; index++) {
      output.push(
        tokenList[tokenList.length - index].tokenURI === undefined ? (
          <div className="imageContainer" key={-index}>
            <img className="logoImg" src={logo} alt="logo" />
          </div>
        ) : (
          <div className="imageContainer" key={-index}>
            <img
              className="tokenImg"
              src={tokenList[tokenList.length - index].tokenURI}
              alt="..."
            />
          </div>
        )
      );
    }
    output.reverse();
    return output;
  };

  const renderExtraNext = () => {
    let output = [];
    output.push(
      <div className="aboutContainer" key={length + 1}>
        <About />
      </div>
    );
    output.push(
      <div className="imageContainer" key={length + 2}>
        <img
          className={
            tokenList[0].tokenURI === undefined ? 'logoImg' : 'tokenImg'
          }
          src={
            tokenList[0].tokenURI === undefined ? logo : tokenList[0].tokenURI
          }
          alt="..."
        />
      </div>
    );
    return output;
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper">
        <button onClick={prev} className="left-arrow">
          &lt;
        </button>

        <div
          className="carousel-content-wrapper"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className={`carousel-content show-2`}
            style={{
              transform: `translateX(-${100 + currentIndex * 50}%)`,
              transition: !transitionEnabled ? 'none' : undefined,
            }}
            onTransitionEnd={() => handleTransitionEnd()}
          >
            {length > show && renderExtraPrev()}
            <div className="aboutContainer" key={0}>
              <About />
            </div>
            {tokenList.map((token) => (
              <TokenSlide key={token.tokenId} token={token} logo={logo} />
            ))}
            {length > show && renderExtraNext()}
          </div>
        </div>
        <button onClick={next} className="right-arrow">
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Carousel;
