import MarketCap from './WidgetMC';
import Change from './WidgetChange';
import SinceLastDip from './WidgetSinceLast';
import NextName from './WidgetNextName';
import { useState, useEffect } from 'react';
import axios from 'axios';

const Widgets = () => {
  const [widgets, setWidgets] = useState([]);

  //Widget Calls
  useEffect(() => {
    const getWidgets = async () => {
      const widgetsFromServer = await fetchWidgetData();
      setWidgets(widgetsFromServer);
    };
    getWidgets();
    return () => {
      setWidgets({});
    };
  }, []);

  const fetchWidgetData = async () => {
    try {
      const response = await axios.get('/widgets');
      return response.data;
    } catch (error) {
      console.error(`fetch widgeterror:${error}`);
    }
  };

  return (
    <div className="row mt-2 mt-md-1 d-flex justify-content-center align-content-center">
      <MarketCap MarketCap={widgets.marketCap} />
      <Change Change={widgets.change} />
      <SinceLastDip DaysElapsed={widgets.daysElapsedSinceLastDip} />
      <NextName Name={widgets.dipName} />
    </div>
  );
};

export default Widgets;
