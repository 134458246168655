import './App.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import Widgets from './components/Widgets';
import Main from './components/Main';
import Menubar from './components/Menubar';
import About from './components/About';
import Sticker from './components/Sticker';
import Carousel from './components/Carousel';
// import tokenList from './components/tokenList';

require('dotenv').config();

function App() {
  const [token, setChartToken] = useState([]);
  const [tokenList, setTokenList] = useState([]);

  //get tokenList
  useEffect(() => {
    const getList = async () => {
      const tokensFromServer = await fetchTokenList();
      setTokenList(tokensFromServer);
    };
    getList();
    return () => {
      setTokenList({});
    };
  }, []);

  const fetchTokenList = async () => {
    try {
      const response = await axios.get('tokenList');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  //function to connect tokenList to Chart
  const showToken = async (tokenId) => {
    const token = await tokenList.find((object) => object.tokenId === tokenId);
    setChartToken(token);
  };

  return (
    <div className="App">
      <div className="container h-100">
        <Menubar />
        <div className="Main">
          <Widgets />
          <Main token={token} />
        </div>
        <div className="d-none d-lg-block">
          <Carousel tokenList={tokenList} showToken={showToken} />
        </div>

        {/* <Sticker /> */}
      </div>
    </div>
  );
}

export default App;
